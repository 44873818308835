import type { UserDispensariesAtom } from '../state/user-dispensaries';

type User = {
  user: UserDispensariesAtom;
};

/* eslint-disable prettier/prettier */
const canadianProvinces = [
  'ab',
  'alberta',
  'bc',
  'britishcolumbia',
  'mb',
  'manitoba',
  'nb',
  'newbrunswick',
  'nl',
  'newfoundlandandlabrador',
  'newfoundland',
  'labrador',
  'ns',
  'novascotia',
  'nt',
  'northwestterritories',
  'nu',
  'nunavut',
  'on',
  'ontario',
  'pe',
  'princeedwardisland',
  'princeedward',
  'qc',
  'quebec',
  'sk',
  'saskatchewan',
  'yt',
  'yukon',
];
/* eslint-enable prettier/prettier */

export function isCanadaLoc({ user }: User) {
  const provinceOrState = user.selectedLocation.State?.toLowerCase().replace(' ', '');
  return canadianProvinces.includes(provinceOrState) || window?.navigator?.language === 'en-CA';
}

export function isCanadaLocByState({ user }: User) {
  const provinceOrState = user.selectedLocation.State?.toLowerCase().replace(' ', '');
  return canadianProvinces.includes(provinceOrState);
}

export function isFloridaLoc({ user }: User) {
  return user.selectedLocation.State?.toLowerCase().replace(' ', '') === 'fl';
}

export function isUtahLoc({ user }: { user: { selectedLocation: { State?: string } } }) {
  return user.selectedLocation.State?.toLowerCase().replaceAll(' ', '') === 'ut';
}

export function isIllinoisLoc({ user }: User) {
  return user.selectedLocation.State?.toLowerCase().replace(' ', '') === 'il';
}

export function isNewYorkLoc({ user }: User) {
  return user.selectedLocation.State?.toLowerCase().replace(' ', '') === 'ny';
}

export function isV4Market({ user }: User) {
  return user.locProfile.UseBioTrack && (isFloridaLoc({ user }) || isNewYorkLoc({ user }));
}
