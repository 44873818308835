import React from 'react';

import i18next from 'i18next';

import { Loader } from 'src/app/components/lib/loader';
import { Tooltip } from 'src/app/components/lib/tooltip';
import { useDarkMode } from 'src/app/state/dark-mode';
import { omit } from 'src/app/utils/omit';

import { StyledButton, ToolTipContainer } from './button.styles';
import { LinkButton } from './link-button';

import type { SxProps } from '@mui/material';
import type { LoadingSpinnerSizeProp } from 'src/app/components/lib/loader/loader';

export type ButtonPropsVariant =
  | 'danger'
  | 'darkBlue'
  | 'hidden'
  | 'lightBlue'
  | 'link-secondary'
  | 'link'
  | 'outlineBlue'
  | 'primary'
  | 'secondary'
  | 'white'
  | 'yellow';

export type ButtonProps = {
  automationId?: string;
  buttonSize?: 'extra-small' | 'large' | 'medium' | 'small';
  className?: string;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  fontSize?: string;
  id?: string;
  label: React.ReactNode | string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  spinnerSize?: keyof typeof LoadingSpinnerSizeProp;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top';
  tooltipText?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonPropsVariant;
  visible?: boolean;
  width?: string;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref): JSX.Element => {
  const {
    variant = 'primary',
    buttonSize = 'medium',
    disabled,
    onClick,
    startIcon,
    endIcon,
    label,
    sx,
    fontSize = '1rem',
    spinnerSize = '1x',
    loading = false,
    automationId = '',
    id = '',
    className,
    tooltipText,
    tooltipPlacement = 'top',
    type = 'button',
    width = 'fit-content',
  } = props;
  const darkMode = useDarkMode();
  const validLabel = typeof label === 'string' ? i18next.t(label) ?? label : label;

  const getValidLoader = () => {
    // the loader must be the white variant when a primary or danger button is disabled in order to be visible
    if (disabled && (variant === 'primary' || variant === 'danger')) {
      return <Loader size={spinnerSize} variant='default' />;
    }

    return <Loader size={spinnerSize} variant='grey' />;
  };

  const validLoader = getValidLoader();

  const buttonLabel = loading ? validLoader : validLabel;

  if (variant === 'link') {
    return (
      <LinkButton
        buttonSize={buttonSize}
        data-testid={automationId}
        disabled={disabled}
        endIcon={endIcon}
        fontSize={fontSize}
        id={id}
        label={label}
        startIcon={startIcon}
        sx={sx}
        type={type}
        onClick={onClick}
      />
    );
  }

  return tooltipText ? (
    <Tooltip placement={tooltipPlacement} title={tooltipText}>
      <ToolTipContainer>
        <StyledButton
          $buttonSize={buttonSize}
          $darkMode={darkMode}
          $fontSize={fontSize}
          $variant={variant}
          $width={width}
          className={className}
          data-testid={automationId}
          disabled={disabled}
          endIcon={endIcon}
          id={id}
          ref={ref}
          startIcon={startIcon}
          sx={sx}
          type={type}
          variant='contained'
          onClick={onClick}
        >
          {buttonLabel}
        </StyledButton>
      </ToolTipContainer>
    </Tooltip>
  ) : (
    <StyledButton
      {...omit(props, ['automationId', 'loading', 'buttonSize', 'tooltipText', 'tooltipPlacement'])}
      $buttonSize={buttonSize}
      $darkMode={darkMode}
      $fontSize={fontSize}
      $variant={variant}
      $width={width}
      className={className}
      data-testid={automationId}
      disabled={disabled}
      endIcon={endIcon}
      id={id}
      ref={ref}
      startIcon={startIcon}
      sx={sx}
      type={type}
      variant='contained'
      onClick={onClick}
    >
      {buttonLabel}
    </StyledButton>
  );
});
